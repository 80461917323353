import * as yup from 'yup';
import { isValidNumber } from 'libphonenumber-js';
// import settings from '@/settings';

export enum AUTH_STEPS {
  'EMAIL',
  'PASSWORD',
  'OTP_VERIFICATION',
  'FORGOT_PASSWORD',
  'RESET_PASSWORD',
}

export const shouldUseCognito = false; // ['reebelo-ca'].includes(settings.store);

export const OTP_LENGTH = 8;
export const PASSWORD_RESET_OTP_LENGTH = 6;

export const PASSWORD_REQUIREMENTS = [
  {
    title: '8+ characters, mix of letters & numbers',
    regex: /^(?=.*[A-Za-z])(?=.*\d).{8,}$/,
  },
  {
    title: 'At least one uppercase & lowercase',
    regex: /^(?=.*?[a-z])(?=.*?[A-Z])/,
  },
  { title: 'At least one special character', regex: /[!@#$%^&*]/ },
];

export const signupFormSchema = yup
  .object()
  .shape({
    firstName: yup.string().required('First name is a required field'),
    lastName: yup.string().required('Last name is a required field'),
    phoneNumber: yup
      .string()
      .test('is-valid-phone', 'Phone number must be a valid format', (value) =>
        // Check if value is a string and not undefined
        value ? isValidNumber(value) : false,
      )
      .optional(),
    email: yup
      .string()
      .email('Email must be a valid email')
      .required('Email is a required field'),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
        'Password must be at least 8 characters, include uppercase, lowercase, a number, and a special character',
      )
      .required('Password is a required field'),
    acceptsMarketing: yup.bool(),
  })
  .required();
